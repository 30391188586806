
.Alert__overlay {
  background-color: #0008;
}

.Alert__Modal {
  min-width: 450px;
  min-height: 100px;
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  max-height: 90%;
    overflow: auto;
}

.Alert__Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Alert__Message-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-bottom: 1px solid #aaa;
  width: 100%;
}

.Alert__Message-title img {
  width: 25px;
  margin-right: 10px;
}

.Alert__Message-content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1.1em;
  font-family: 'Monserrat', sans-serif;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
}

.Alert__Message-content p {
  max-width: 400px;
  text-align: center;
}

.Alert__ProgressBar-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  height: 25px;
  background: #ddd;
  margin: 10px 0;
  border-radius: 15px;
  position: relative;
}

.Alert__Progressbar {
  background: linear-gradient(90deg, #4aa7e5, #04458f);
  background-size: 320px;
  height: 100%;
  min-width: 25px;
  transition: all 0.5s;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.Alert__Progresbar-anim {
  position: absolute;
  width: 40px;
  height: 25px;
  background: linear-gradient(90deg, #0000, #fff8, #fff8, #0000);
  left: 0;
  margin-left: -50px;
  animation: shineLoadBar 1s infinite ease-out;
}

@keyframes shineLoadBar {
  0% {
    margin-left: -50%;
  }
  80%,
  100% {
    margin-left: 100%;
  }
}

.Alert__Message-content button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blue;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all .3s;
}

.Alert__Message-content button:hover {
  background-color: blue;
}

.Alert__Button-area {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Alert__Button-area button {
  width: 200px;
}

@media (max-width: 500px) {
  .Alert__overlay  {
    padding: 10px;
    box-sizing: border-box;
  }

  .Alert__Modal {
    width: 100%;
    min-width: unset;
  }

  .Alert__Button-area {
    flex-flow: column-reverse;
  }
  
  .Alert__Button-area button {
    width: 100%;
  }
}

@media (max-width: 400px) {

  .Alert__Message-title {
    font-size: 0.6em;
    border-bottom: 1px solid #aaa;
    width: 100%;
  }

  .Alert__Message-title img {
    width: 22px;
  }
}
