@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.table-container ::-webkit-scrollbar {
  height: 12px;
  width: 10px;
  background: #C4C4C4;
}

.table-container ::-webkit-scrollbar-thumb {
  background: #F84812;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.table-container ::-webkit-scrollbar-corner {
  background: #C4C4C4;
}

a {
  text-decoration: none
}

input {
  outline: none;
  appearance: none;
}

input:hover {
  appearance: none;
}

textarea {
  outline: none;
  appearance: none;
}

textarea:hover {
  appearance: none;
}

select {
  appearance: none;
}

button {
  outline: none;
}

.language-picker button {
  border: none !important
}

.language-picker ul {
  padding: 8px 6px !important
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  background: transparent;
  border: none;
}

td {
    text-align: center;
}
th {
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@-webkit-keyframes autofill {
  0%,100% {
      color: #666;
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
